<template>
  <div class="navigation">
    <BaseIcon
      name="logo"
      :width="90"
      :height="36"
      class="logo"
    />
    <button
      class="btn btn__small"
      @click="scrollToAnchor"
    >
      contact us
    </button>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';

export default {
  name: 'Navigation',
  components: { BaseIcon },
  methods: {
    scrollToAnchor() {
      const el = document.getElementById('feedback');
      if (!el) return;

      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss">
.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>
