var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"wrapper platforms"},[_c('h2',{staticClass:"title title__h2 title__blue"},[_vm._v(" Integrated with major tracking platforms ")]),_c('div',{staticClass:"platforms-logos"},_vm._l((_vm.logos),function(logo,i){return _c('picture',{key:i},[_c('source',{staticClass:"platforms-img",attrs:{"type":"image/webp","srcset":require(`@/assets/img/platforms/webp/${logo}.webp`) +
        ' 1x, ' +
        require(`@/assets/img/platforms/webp/${logo}@2x.webp`) +
        '2x',"alt":"platform logo"}}),_c('img',{staticClass:"platforms-img",attrs:{"src":require(`@/assets/img/platforms/png/${logo}.png`),"srcset":require(`@/assets/img/platforms/png/${logo}.png`) +
        ' 1x, ' +
        require(`@/assets/img/platforms/png/${logo}@2x.png`) +
        '2x',"alt":"platform logo"}})])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }