<template>
  <section class="wrapper about">
    <h2 class="title title__h2 title__blue">
      We help businesses find
      new customers
    </h2>
    <BaseCard
        v-for="(info, i) in aboutUs"
        :key="i"
        :icon="info.icon"
        :text="info.text"
    />
  </section>
</template>

<script>
import BaseCard from '@/components/Base/BaseCard';

export default {
  name: 'About',
  components: {
    BaseCard,
  },
  data: () => ({
    aboutUs: [
      {
        icon: 'phone',
        text: 'Over 5 years of mobile development experience with many satisfied customers!'
      },
      {
        icon: 'geo',
        text: 'More than 70 countries in our core GEO'
      },
      {
        icon: 'portfolio',
        text: 'Portfolio of more than 300 completed projects'
      },
      {
        icon: 'users',
        text: 'More than 50 professionals in mobile development, app publishing, media buying, design, analysis, and accounting',
      },
      {
        icon: 'user',
        text: 'Deep marketing expertise. Helps us develop and implement the perfect strategy for you to attract new users',
      },
    ],
  }),
};
</script>

<style lang="scss">
.about {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (min-width: $size-l) {
    flex-direction: row;
    gap: 16px;
  }

  @media (min-width: $size-xl) {
    gap: 40px;
  }

  .title {
    width: 288px;
    margin: 0 auto 30px;
    text-align: center;

    @media (min-width: $size-m) {
      width: 472px;
    }

    @media (min-width: $size-l) {
      flex: 0 0 472px;
      width: 100%;
      height: 150px;
      margin: 0;
      text-align: left;
    }

    @media (min-width: $size-xl) {
      flex: 0 0 570px;
    }
  }

  .base-card {
    height: 91px;
    padding: 5px 12px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    @media (min-width: $size-m) {
      height: 100px;
      padding: 10px 30px;
    }

    @media (min-width: $size-l) {
      flex: 0 0 472px;
      height: 150px;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    @media (min-width: $size-xl) {
      flex: 0 0 570px;
      padding: 10px 45px 10px 40px;
    }
  }

  .base-icon {
    flex: 0 0 30px;
    height: 30px;

    @media (min-width: $size-m) {
      flex: 0 0 40px;
      height: initial;
    }
  }
}
</style>
