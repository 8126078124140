<template>
  <footer class="footer">
    <div class="wrapper footer-content">
      <BaseIcon
          name="logo"
          :width="90"
          :height="36"
          class="logo"
      />
      <p class="footer-copyright">
        copyright © 2023
      </p>
    </div>
  </footer>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';

export default {
  name: 'Footer',
  components: { BaseIcon },
};
</script>

<style lang="scss">
.footer {
  padding: 20px 0;
  background: var(--color-bg-footer);

  @media (min-width: $size-l) {
    padding: 34px 0;
  }

  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-copyright {
    font-size: 10px;
    line-height: 1.2;
    color: var(--color-text-ghost);

    @media (min-width: $size-l) {
      font-size: 12px;
    }
  }
}
</style>
