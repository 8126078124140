<template>
  <form
      class="form"
      novalidate
      @submit.prevent="onSubmit"
  >
    <h3 class="title title__h3 title__blue">
      Leave a message and <br>we will contact you!
    </h3>
    <input
        v-for="(field, i) in form"
        :key="i"
        v-model="field.value"
        :type="field.type"
        :placeholder="field.placeholder"
        class="form-input"
        :class="{error: v$.form[i].$error}"
    >
    <button
        type="submit"
        class="btn btn__large"
    >
      sent
    </button>
  </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import axios from 'axios'

const phoneValidation = (phone) => {
  const regex = /^[0-9()+-]+$/
  return regex.test(phone)
}

export default {
  name: 'Form',
  setup: () => ({ v$: useVuelidate() }),
  data: () => ({
    form: {
      name: {
        value: '',
        type: 'text',
        placeholder: 'name',
      },
      phone: {
        value: '',
        type: 'tel',
        placeholder: '+357 99-999-999',
      },
      email: {
        value: '',
        type: 'email',
        placeholder: 'email',
      },
    },
  }),
  validations: () => ({
    form: {
      name: {
        value: {
          required,
        },
      },
      phone: {
        value: {
          required,
          phoneValidation,
        },
      },
      email: {
        value: {
          required,
          email,
        },
      },
    },
  }),
  methods: {
    async onSubmit () {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect && this.v$.$error) return;

      const formData = Object.keys(this.form)
          .reduce((acc, key) => {
            acc[key] = this.form[key].value
            return acc;
          }, {});

      await axios.post(
          '/mail.php',
          formData,
          {
            headers: {
              'content-type': 'multipart/form-data',
            },
          },
      )
          .then(() => {
            Object.keys(this.form)
                .forEach(key => {
                  this.form[key].value = '';
                });
            // skip validation errors
            this.$nextTick(() => {
              this.v$.$reset()
            })
          })
          .catch(() => {});
    },
  },
}
</script>

<style lang="scss">
.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  text-align: center;
  background: var(--color-body);
  box-shadow: var(--color-shadow);
  border-radius: $border-radius-m;

  @media (min-width: $size-m) {
    padding: 24px 16px;
  }

  @media (min-width: $size-l) {
    padding: 30px;
  }

  @media (min-width: $size-xl) {
    padding: 40px 33px;
  }

  .title, &-input {
    margin-bottom: 16px;
  }

  &-input {
    padding: 15px 20px;
    border: 2px solid var(--color-input-border);
    border-radius: 30px;
    transition: all ease-in-out 0.2s;
    font-family: 'inter', monospace;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.2;
    color: var(--color-text-dark);

    @media (min-width: $size-l) {
      padding: 19px 20px;
      font-size: 14px;
    }

    @media (min-width: $size-xl) {
      padding: 21px 20px;
      font-size: 16px;
    }

    &:focus,
    &:hover {
      border: 2px solid var(--color-text-blue);
    }

    &::placeholder {
      text-transform: uppercase;
      color: var(--color-input-placeholder);
    }
  }

  .error {
    border: 2px solid var(--color-input-error);
  }
}
</style>
