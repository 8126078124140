<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMaxYMax meet"
    :width="width"
    :height="height"
    class="base-icon"
  >
    <use :xlink:href="path" />
  </svg>
</template>

<script>
import sprite from '@/assets/img/icons/icons-sprite.svg';

export default {
  name: 'BaseIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 36,
    },
    height: {
      type: Number,
      default: 36,
    },
  },
  computed: {
    path() {
      return `${sprite}#${this.name}`;
    },
  },
};
</script>
