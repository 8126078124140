<template>
  <section class="works">
    <div class="wrapper works-content">
      <h2 class="title title__h2 title__white">
        what we do
      </h2>
      <p class="text text__small works-text">
        We are able to offer full-time partnerships to our clients,
        including all aspects needed to be a success
      </p>
      <div class="works-about">
        <BaseCard
            v-for="(text,i) in texts"
            :key="i"
            theme="dark"
            :text="text"
            text-size="small"
        />
      </div>
      <picture
          v-for="(image, i) in images"
          :key="i"
      >
        <source
            type="image/webp"
            :srcset="
          require(`@/assets/img/webp/${image.name}.webp`) +
          ' 1x, ' +
          require(`@/assets/img/webp/${image.name}@2x.webp`) +
          '2x'
        "
            alt="platform logo"
            :class="['works-img', `works-img__${image.class}`]"
        />
        <img
            :src="require(`@/assets/img/png/${image.name}.png`)"
            :srcset="
          require(`@/assets/img/png/${image.name}.png`) +
          ' 1x, ' +
          require(`@/assets/img/png/${image.name}@2x.png`) +
          '2x'
        "
            alt="platform logo"
            :class="['works-img', `works-img__${image.class}`]"
        />
      </picture>
    </div>
  </section>
</template>

<script>
import BaseCard from '@/components/Base/BaseCard';

export default {
  name: 'Works',
  components: { BaseCard },
  data: () => ({
    texts: [
      'App development',
      'Marketing and distribution',
      'Market intelligence and co-development product expertise',
      'Ad mediation',
      'Analytics support',
    ],
    images: [
      {
        name: 'works-img-1',
        class: 'top'
      },
      {
        name: 'works-img-2',
        class: 'bottom'
      },
    ],
  }),
};
</script>

<style lang="scss">
.works {
  padding: 40px 0 71px;
  background: var(--color-bg-works);
  background-blend-mode: overlay;
  mix-blend-mode: normal;

  @media (min-width: $size-m) {
    padding: 40px 0;
  }

  @media (min-width: $size-l) {
    padding: 80px 0;
  }

  @media (min-width: $size-xl) {
    padding: 100px 0;
  }

  &-content {
    position: relative;
  }

  &-img {
    position: absolute;
    right: 0;

    &__top {
      display: none;

      @media (min-width: $size-m) {
        display: block;
        top: -110px;
        width: 280px;
      }

      @media (min-width: $size-l) {
        top: -165px;
        width: 354px;
      }

      @media (min-width: $size-xl) {
        top: -210px;
        width: 456px;
      }
    }

    &__bottom {
      bottom: -90px;
      width: 192px;

      @media (min-width: $size-m) {
        width: 322px;
      }

      @media (min-width: $size-l) {
        bottom: -136px;
        width: 409px;
      }

      @media (min-width: $size-xl) {
        width: 527px;
        bottom: -175px;
      }
    }
  }

  .title {
    text-align: center;

    @media (min-width: $size-m) {
      text-align: left;
    }
  }

  &-text {
    max-width: 320px;
    margin: 20px 0;

    @media (min-width: $size-m) {
      width: 417px;
      max-width: 100%;
      margin: 10px 0 39px;
    }

    @media (min-width: $size-l) {
      width: 477px;
      margin: 20px 0 46px;
    }

    @media (min-width: $size-xl) {
      width: 607px;
      margin: 23px 0 40px;
    }
  }

  &-about {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px 9px;

    @media (min-width: $size-m) {
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
      width: 580px;
    }

    @media (min-width: $size-l) {
      gap: 30px;
      width: 750px;
    }

    .base-card {
      height: 100px;
      padding: 10px;

      @media (min-width: $size-m) {
        height: 123px;
        padding: 10px 22px;
      }

      @media (min-width: $size-l) {
        height: 150px;
      }
    }
  }
}
</style>
