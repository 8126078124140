<template>
  <section class="wrapper platforms">
    <h2 class="title title__h2 title__blue">
      Integrated with major
      tracking platforms
    </h2>
    <div class="platforms-logos">
      <picture
          v-for="(logo, i) in logos"
          :key="i"
      >
        <source
            type="image/webp"
            :srcset="
          require(`@/assets/img/platforms/webp/${logo}.webp`) +
          ' 1x, ' +
          require(`@/assets/img/platforms/webp/${logo}@2x.webp`) +
          '2x'
        "
            alt="platform logo"
            class="platforms-img"
        />
        <img
            :src="require(`@/assets/img/platforms/png/${logo}.png`)"
            :srcset="
          require(`@/assets/img/platforms/png/${logo}.png`) +
          ' 1x, ' +
          require(`@/assets/img/platforms/png/${logo}@2x.png`) +
          '2x'
        "
            alt="platform logo"
            class="platforms-img"
        />
      </picture>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Platforms',
  data: () => ({
    logos: [
      'adjust',
      'appmetrica',
      'kochava',
      'appflyer',
      'tune',
    ],
  }),
};
</script>

<style lang="scss">
.platforms {
  .title {
    width: 288px;
    margin: 0 auto 26px;
    text-align: center;

    @media (min-width: $size-m) {
      width: 503px;
      margin: 0 auto 60px;
    }

    @media (min-width: $size-l) {
      width: 660px;
      margin: 0 auto 90px;
    }
  }

  &-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px 15px;

    @media (min-width: $size-m) {
      flex-wrap: nowrap;
      justify-content: space-between;
      gap: 0;
    }
  }

  &-img {
    width: 90px;

    @media (min-width: $size-m) {
      width: 140px;
    }

    @media (min-width: $size-l) {
      width: 180px;
    }

    @media (min-width: $size-xl) {
      width: initial;
    }
  }
}
</style>
