<template>
  <div
    class="base-card"
    :class="`base-card__${theme}`"
  >
    <BaseIcon
      v-if="icon"
      :name="icon"
      :width="width"
      :height="height"
    />
    <p
      class="text"
      :class="`text__${textSize}`"
      v-html="text"
    />
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';

export default {
  name: 'BaseCard',
  components: { BaseIcon },
  props: {
    theme: {
      type: String,
      default: 'light',
    },
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 40,
    },
    height: {
      type: Number,
      default: 40,
    },
    textSize: {
      type: String,
      default: 'medium',
    },
  },
};
</script>

<style lang="scss">
.base-card {
  box-shadow: var(--color-shadow);
  border-radius: $border-radius-m;

  &__dark {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: var(--color-card-bg-dark);

    > p {
      color: var(--color-text-white);
    }
  }

  &__light {
    display: flex;
    align-items: center;
    background: var(--color-card-bg-light);
    border: 1px solid var(--color-card-border);

    > p {
      margin-left: 22px;
      color: var(--color-text-dark);
    }
  }
}
</style>
