var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"wrapper",attrs:{"id":"feedback"}},[_c('div',{staticClass:"feedback"},[_c('picture',[_c('source',{staticClass:"feedback-img",attrs:{"type":"image/webp","srcset":require(`@/assets/img/webp/feedback-img.webp`) +
        ' 1x, ' +
        require(`@/assets/img/webp/feedback-img@2x.webp`) +
        '2x',"alt":"feedback image"}}),_c('img',{staticClass:"feedback-img",attrs:{"src":require(`@/assets/img/png/feedback-img.png`),"srcset":require(`@/assets/img/png/feedback-img.png`) +
        ' 1x, ' +
        require(`@/assets/img/png/feedback-img@2x.png`) +
        '2x',"alt":"feedback image"}})]),_vm._m(0),_c('Form')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feedback-content"},[_c('h2',{staticClass:"title title__h2 title__white feedback-title"},[_vm._v(" why should you choose us? ")]),_c('p',{staticClass:"text text__medium"},[_vm._v(" Multi tech century is an international project that has been in the industry for 5 years. Multi tech century leads the way in the development and promotion of both gaming and non-gaming mobile applications such as sports, food delivery, e-com, video streaming, and utilities. ")])])
}]

export { render, staticRenderFns }