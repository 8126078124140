<template>
  <div id="app">
    <Header/>
    <About/>
    <Platforms/>
    <Works/>
    <Feedback/>
    <Footer/>
  </div>
</template>

<script>

import * as Blocks from '@/components'

export default {
  name: 'App',
  components: {
    ...Blocks
  },
}
</script>

<style lang="scss">
#app {
  position: relative;
  display: flex;
  flex-direction: column;

  gap: 60px 0;

  @media (min-width: $size-m) {
    gap: 80px 0;
  }

  @media (min-width: $size-l) {
    gap: 120px 0;
  }

  @media (min-width: $size-xl) {
    gap: 160px 0;
  }
}
</style>
