var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"works"},[_c('div',{staticClass:"wrapper works-content"},[_c('h2',{staticClass:"title title__h2 title__white"},[_vm._v(" what we do ")]),_c('p',{staticClass:"text text__small works-text"},[_vm._v(" We are able to offer full-time partnerships to our clients, including all aspects needed to be a success ")]),_c('div',{staticClass:"works-about"},_vm._l((_vm.texts),function(text,i){return _c('BaseCard',{key:i,attrs:{"theme":"dark","text":text,"text-size":"small"}})}),1),_vm._l((_vm.images),function(image,i){return _c('picture',{key:i},[_c('source',{class:['works-img', `works-img__${image.class}`],attrs:{"type":"image/webp","srcset":require(`@/assets/img/webp/${image.name}.webp`) +
        ' 1x, ' +
        require(`@/assets/img/webp/${image.name}@2x.webp`) +
        '2x',"alt":"platform logo"}}),_c('img',{class:['works-img', `works-img__${image.class}`],attrs:{"src":require(`@/assets/img/png/${image.name}.png`),"srcset":require(`@/assets/img/png/${image.name}.png`) +
        ' 1x, ' +
        require(`@/assets/img/png/${image.name}@2x.png`) +
        '2x',"alt":"platform logo"}})])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }