<template>
  <section
      id="feedback"
      class="wrapper"
  >
    <div class="feedback">
      <picture>
        <source
            type="image/webp"
            :srcset="
          require(`@/assets/img/webp/feedback-img.webp`) +
          ' 1x, ' +
          require(`@/assets/img/webp/feedback-img@2x.webp`) +
          '2x'
        "
            alt="feedback image"
            class="feedback-img"
        />
        <img
            :src="require(`@/assets/img/png/feedback-img.png`)"
            :srcset="
          require(`@/assets/img/png/feedback-img.png`) +
          ' 1x, ' +
          require(`@/assets/img/png/feedback-img@2x.png`) +
          '2x'
        "
            alt="feedback image"
            class="feedback-img"
        />
      </picture>
      <div class="feedback-content">
        <h2 class="title title__h2 title__white feedback-title">
          why should
          you choose us?
        </h2>
        <p class="text text__medium">
          Multi tech century is an international project that has been in the industry for 5 years. Multi tech century
          leads the way in the development and promotion of both gaming and non-gaming mobile applications such as
          sports,
          food delivery, e-com, video streaming, and utilities.
        </p>
      </div>
      <Form/>
    </div>
  </section>
</template>

<script>
import { Form } from '@/components/Form'

export default {
  name: 'Feedback',
  components: { Form },
};
</script>

<style lang="scss">
.feedback {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: $size-m) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    height: 361px;
    padding: 70px 19px 70px 30px;
    border-radius: $border-radius-m;
    background: var(--color-bg-feedback);
  }

  @media (min-width: $size-l) {
    height: 430px;
    padding: 100px 30px 100px 60px;
  }

  @media (min-width: $size-xl) {
    height: 458px;
    padding: 100px 60px;
  }

  &-title {
    width: 205px;
    margin: 0 auto;

    @media (min-width: $size-m) {
      width: 100%;
      margin: 0;
    }
  }

  &-img {
    position: absolute;
    top: 199px;
    right: 10px;
    width: 102px;

    @media (min-width: $size-m) {
      left: 18px;
      top: -63px;
      bottom: initial;
      width: 130px;
    }

    @media (min-width: $size-l) {
      top: -76px;
      width: 154px;
    }

    @media (min-width: $size-xl) {
      top: -140px;
      width: 227px;
    }
  }

  &-content {
    margin-bottom: 36px;
    padding: 50px 16px 57px;
    background: var(--color-bg-feedback);
    border-radius: $border-radius-m;

    @media (min-width: $size-m) {
      flex: 0 0 329px;
      margin-bottom: 0;
      padding: 0;
      background: none;
      border-radius: 0;
    }

    @media (min-width: $size-l) {
      flex: 0 0 373px;
    }

    @media (min-width: $size-xl) {
      flex: 0 0 441px;
    }

    .title {
      margin-bottom: 16px;
      text-align: center;

      @media (min-width: $size-m) {
        text-align: left;
      }
    }
  }

  .form {
    @media (min-width: $size-m) {
      flex: 0 0 345px;
    }

    @media (min-width: $size-l) {
      flex: 0 0 442px;
    }

    @media (min-width: $size-xl) {
      flex: 0 0 497px;
    }
  }
}
</style>
