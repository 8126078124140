<template>
  <div class="wrapper">
    <header class="header">
      <Navigation/>
      <h1 class="title title__h1">
        The Top Mobile Marketing
        & IT Development Agency
      </h1>
      <p class="text text__large">
        With over 5,000,000 monthly installations
        from all major traffic sources worldwide.
      </p>
      <picture>
        <source
            type="image/webp"
            :srcset="
          require(`@/assets/img/webp/header-img.webp`) +
          ' 1x, ' +
          require(`@/assets/img/webp/header-img@2x.webp`) +
          '2x'
        "
            alt="image"
            class="header-img"
        />
        <img
            :src="require(`@/assets/img/png/header-img.png`)"
            :srcset="
          require(`@/assets/img/png/header-img.png`) +
          ' 1x, ' +
          require(`@/assets/img/png/header-img@2x.png`) +
          '2x'
        "
            alt="image"
            class="header-img"
        />
      </picture>
    </header>
  </div>
</template>

<script>
import { Navigation } from '@/components/Navigation';

export default {
  name: 'Header',
  components: { Navigation },
};
</script>

<style lang="scss">
.header {
  position: relative;
  margin-top: 40px;
  padding: 16px 16px 181px;
  background: var(--color-bg-header);
  background-blend-mode: overlay;
  mix-blend-mode: normal;
  border-radius: $border-radius-m;

  @media (min-width: $size-m) {
    padding: 30px 30px 168px;
  }

  @media (min-width: $size-l) {
    padding: 30px 30px 178px;
  }

  @media (min-width: $size-xl) {
    padding: 37px 60px 237px;
  }

  .title {
    max-width: 486px;
    margin-bottom: 30px;

    @media (min-width: $size-m) {
      width: 486px;
      max-width: 100%;
    }
    @media (min-width: $size-l) {
      width: 638px;
    }
    @media (min-width: $size-xl) {
      width: 855px;
    }
  }

  .navigation {
    margin-bottom: 72px;
  }

  .text {
    max-width: 350px;
    @media (min-width: $size-m) {
      width: 400px;
      max-width: 100%;
    }
    @media (min-width: $size-l) {
      width: 450px;
    }
    @media (min-width: $size-xl) {
      width: 563px;
    }
  }

  &-img {
    position: absolute;
    bottom: -43px;
    right: 0;
    width: 271px;

    @media (min-width: $size-m) {
      bottom: -70px;
      width: 455px;
    }

    @media (min-width: $size-l) {
      bottom: -80px;
      width: 501px;
    }

    @media (min-width: $size-xl) {
      bottom: -95px;
      width: 625px;
    }
  }
}
</style>
