var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('header',{staticClass:"header"},[_c('Navigation'),_c('h1',{staticClass:"title title__h1"},[_vm._v(" The Top Mobile Marketing & IT Development Agency ")]),_c('p',{staticClass:"text text__large"},[_vm._v(" With over 5,000,000 monthly installations from all major traffic sources worldwide. ")]),_c('picture',[_c('source',{staticClass:"header-img",attrs:{"type":"image/webp","srcset":require(`@/assets/img/webp/header-img.webp`) +
        ' 1x, ' +
        require(`@/assets/img/webp/header-img@2x.webp`) +
        '2x',"alt":"image"}}),_c('img',{staticClass:"header-img",attrs:{"src":require(`@/assets/img/png/header-img.png`),"srcset":require(`@/assets/img/png/header-img.png`) +
        ' 1x, ' +
        require(`@/assets/img/png/header-img@2x.png`) +
        '2x',"alt":"image"}})])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }